import React from "react";

const RegistrationMessage = ({ displayMessage }) => {
  return (
    <div
      className={"mb-0 text-center"}
      dangerouslySetInnerHTML={{ __html: displayMessage }}
    />
  );
};

export default RegistrationMessage;
