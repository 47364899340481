import React, { useEffect, useState, useCallback, useRef } from "react";
import WebLayout from "../layout/WebLayout";
import { Link } from "react-router-dom";
import axios from "axios";
import { BaseUrl, ERM_PDF } from "../Constant/BaseUrl";
import TermsAndModal from "../components/TermsAndModal";
import PrivacyModal from "../components/PrivacyModal";
import RegistrationMessage from "../components/RegistrationMessage";

const HowToRegister = () => {
  const openPdf = () => {
    window.open(ERM_PDF, "_blank", "width=800,height=600");
  };

  return (
    <div className="how-to-register">
      <h2
        className="text-2xl font-bold"
        style={{
          background: "#f7a400",
          alignSelf: "center",
          padding: 10,
          color: "#FFFFFF",
        }}
      >
        How to Register?
      </h2>
      <div className="mt-6">
        <button onClick={openPdf} className="btn btn-primary">
          📄 Check Registration Process
        </button>
      </div>
    </div>
  );
};

function ErmRegistration() {
  const errorRef = useRef(null);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    password: "",
    state: "",
    district: "",
    pinCode: "",
    address: "",
  });

  const [stateData, setStateData] = useState([]);
  const [districtData, setDistrictData] = useState([]);
  const [termsModal, setTermsModal] = useState(false);
  const [privacyModal, setPrivacyModal] = useState(false);
  const [apiMessage, setApiMessage] = useState({
    type: "",
    message: "",
  });

  const [message, setMessage] = useState({
    display: false,
    email: "",
    password: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "phone" || name === "pinCode") {
      const numericValue = value.replace(/[^0-9]/g, "");
      setFormData((prevState) => ({ ...prevState, [name]: numericValue }));
    } else {
      setFormData((prevState) => ({ ...prevState, [name]: value }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        `${BaseUrl}erm-registration`,
        JSON.stringify(formData),
        { headers: { "Content-Type": "application/json" } },
      );

      if (response.data.status) {
        setMessage({
          display: true,
          email: formData.email,
          password: formData.password,
        });
        resetForm();
      } else {
        displayApiMessage("error_message", response.data.msg);
      }
    } catch (error) {
      const errorMessage =
        error.response?.data?.msg ||
        "An error occurred while submitting the form.";
      displayApiMessage("error_message", errorMessage);
    }
  };

  const resetForm = () => {
    setFormData({
      name: "",
      email: "",
      phone: "",
      password: "",
      state: "",
      district: "",
      pinCode: "",
      address: "",
    });
    setDistrictData([]);
  };

  const displayApiMessage = (type, message) => {
    setApiMessage({ type, message });

    // Scroll to error message when an error occurs
    setTimeout(() => {
      if (errorRef.current) {
        errorRef.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    }, 100);

    // Hide message after 3 seconds
    setTimeout(() => setApiMessage({ type: "", message: "" }), 3000);
  };

  const fetchStateData = useCallback(async () => {
    try {
      const response = await axios.get(`${BaseUrl}state`);
      setStateData(response.data.data || []);
    } catch (error) {}
  }, []);

  const fetchDistrictData = useCallback(async (state_id) => {
    if (!state_id) return setDistrictData([]);
    try {
      const response = await axios.post(
        `${BaseUrl}district`,
        JSON.stringify({ state_id }),
        { headers: { "Content-Type": "application/json" } },
      );
      setDistrictData(response.data.data || []);
    } catch (error) {}
  }, []);

  useEffect(() => {
    fetchStateData();
  }, [fetchStateData]);

  useEffect(() => {
    fetchDistrictData(formData.state);
  }, [formData.state, fetchDistrictData]);

  return (
    <WebLayout>
      <main>
        <section
          className="breadcrumb__area breadcrumb__bg"
          style={{ padding: 20 }}
        >
          <div className="container">
            {message.display ? (
              <div className={"row"}>
                <RegistrationMessage
                  displayMessage={`<style> h1 { color: #4a4a4a; margin-bottom: 10px; } p { font-size: 16px; color: #4a4a4a; margin-bottom: 20px; } .credentials { background-color: #f9f9f9; padding: 20px; border: 2px dashed #6e8efb; border-radius: 10px; margin: 20px 0; } .credentials { display: block; margin: 10px 0; font-weight: bold; color: #333; } .login-btn { background: linear-gradient(135deg, #6e8efb, #a777e3); color: white; padding: 15px 25px; border: none; border-radius: 8px; text-decoration: none; font-size: 16px; font-weight: bold; cursor: pointer; transition: background 0.3s ease, transform 0.2s ease; display: inline-block; } .login-btn:hover { background: linear-gradient(135deg, #5d7ae3, #9163d4); transform: scale(1.05); } </style><div class="container"> <h1>Welcome to Abhhyam Secured Life!</h1> <p><strong>Let's offer a secure life to each and every entity.</strong></p> <div class="credentials"> <p><strong>YOUR LOGIN CREDENTIALS</strong></p> <p><strong>Email: ${message.email}</></p> <p class="mb-0"><strong>Password: ${message.password}</strong></p> </div> <a href="https://erm.abhhyamsecure.com/" target="_blank" class="login-btn">👉 Click Here to Login</a> </div>`}
                />
              </div>
            ) : (
              <div className="row">
                <TermsAndModal
                  termsModal={termsModal}
                  setTermsModal={setTermsModal}
                />
                <PrivacyModal
                  privacyModal={privacyModal}
                  setPrivacyModal={setPrivacyModal}
                />
                <div
                  className={"col-lg-12 mb-3"}
                  style={{
                    background: "black",
                  }}
                >
                  <h1 className="title text-center m-0 text-white registrationHeading">
                    ENTITY REPRESENTATIVE MEMBER (ERM)
                  </h1>
                </div>
                <div className="col-lg-7">
                  <p className={"contentHeading"}>
                    <span
                      style={{
                        fontWeight: 800,
                      }}
                    >
                      All individual sales and marketing leaders or marketing
                      company want to represent the Abhhyam Secured Life can
                      register as Abhhyam Secured Life Entity Representative
                      Member (ERM). Only Registered ERM will be entitled for the
                      following benefits:
                    </span>
                  </p>
                  <ul style={{ listStyle: "decimal" }}>
                    <li>
                      <b>ERM's Pension Points (PP):</b> Entity Representative
                      Members (ERM) earn 2% PP for each ASL ID account allotted
                      by an Entity Member Company (EMC).
                    </li>
                    <li>
                      <b>Month Closing:</b> Total PP earned by the ERM is
                      converted into Representative Member Fund (RMF) at
                      month-end.
                    </li>
                    <li>
                      <b>Representative Member Bonus (RMB):</b> 50% of RMF is
                      released as RMB.
                    </li>
                    <li>
                      <b>Pension Bond Fund (PBF):</b> 50% of RMF is kept as PBF
                      (maximum Rs. 5 crore allowed over 144 months).
                    </li>
                    <li>
                      <b>ERM Account on Maturity:</b> After 144 months, ERM's
                      account is removed from ASL and a new ASL ID account is
                      activated if EMC remains active and availing services.
                    </li>
                    <li>
                      <b>No PP Capping for ERM:</b> No cap on PP for ERM ASL ID.
                      A new ID is created for every 200 PP.
                    </li>
                    <li>
                      <b>Maximum RMB Capping:</b> RMB is capped at Rs. 10
                      lakhs/month, and an equal amount is deposited in the PBF,
                      subject to a PBF cap of Rs. 5 crore over 144 months.
                    </li>
                    <li>
                      <b>Pension Bond Release:</b> Upon achieving maximum PBF, a
                      pension bond is released by a national insurance company.
                    </li>
                  </ul>
                  <HowToRegister />
                </div>
                <div className="col-lg-5">
                  {apiMessage.message && (
                    <p ref={errorRef} className={`${apiMessage.type}`}>
                      {apiMessage.message}
                    </p>
                  )}
                  <div
                    className="contact__form-wrap"
                    style={{ margin: 0, background: "wheat" }}
                  >
                    <form id="registrationForm" onSubmit={handleSubmit}>
                      <div className="row">
                        <InputField
                          name="name"
                          value={formData.name}
                          handleChange={handleInputChange}
                          placeholder="Enter Full Name *"
                          required
                        />
                        <InputField
                          name="email"
                          value={formData.email}
                          handleChange={handleInputChange}
                          placeholder="Enter Email *"
                          type={"email"}
                          required
                        />
                        <InputField
                          name="phone"
                          value={formData.phone}
                          handleChange={handleInputChange}
                          placeholder="Enter Phone Number *"
                          required
                          type="tel"
                          maxLength={10}
                        />
                        <InputField
                          name="password"
                          value={formData.password}
                          handleChange={handleInputChange}
                          placeholder="Enter Password *"
                          required
                          type="password"
                        />
                        <SelectField
                          name="state"
                          value={formData.state}
                          options={stateData}
                          handleChange={handleInputChange}
                          placeholder="Select State *"
                          required
                        />
                        <SelectField
                          name="district"
                          value={formData.district}
                          options={districtData}
                          handleChange={handleInputChange}
                          placeholder="Select District *"
                          required
                        />
                        <InputField
                          name="pinCode"
                          value={formData.pinCode}
                          handleChange={handleInputChange}
                          placeholder="Enter Pincode *"
                          required
                          maxLength={6}
                        />
                        <InputField
                          name="address"
                          value={formData.address}
                          handleChange={handleInputChange}
                          placeholder="Enter Address *"
                          required
                        />
                      </div>
                      <div className="form-grp checkbox-grp">
                        <input
                          type="checkbox"
                          id="termsCheckbox"
                          checked
                          readOnly
                        />
                        <label style={{ fontWeight: "bold" }}>
                          I accept{" "}
                          <Link
                            to={"#"}
                            onClick={() => setTermsModal(true)}
                            style={{ fontWeight: "bold", color: "#3913ff" }}
                          >
                            Terms & Conditions
                          </Link>{" "}
                          and{" "}
                          <Link
                            to={"#"}
                            onClick={() => setPrivacyModal(true)}
                            style={{ fontWeight: "bold", color: "#3913ff" }}
                          >
                            Privacy Policy
                          </Link>
                        </label>
                      </div>
                      <div className="submit-button text-center">
                        <button type="submit" className="registerBtn">
                          REGISTER
                        </button>
                        <p className={"m-2"}>
                          <b>OR</b>
                        </p>
                        <Link
                          to={"https://erm.abhhyamsecure.com/"}
                          target={"_blank"}
                        >
                          <button type="button" className="loginBtn">
                            LOGIN
                          </button>
                        </Link>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            )}
          </div>
        </section>
      </main>
    </WebLayout>
  );
}

function InputField({ type, name, value, handleChange, placeholder, ...rest }) {
  const [showPassword, setShowPassword] = useState(false);

  // Toggle the password visibility
  const togglePasswordVisibility = () => setShowPassword((prev) => !prev);

  return (
    <div className="col-md-12">
      <div className="form-grp" style={{ position: "relative" }}>
        <input
          type={
            type === "password" && !showPassword
              ? "password"
              : type === "email"
                ? "email"
                : "text"
          }
          name={name}
          value={value}
          onChange={handleChange}
          placeholder={placeholder}
          {...rest}
          style={{
            background: "#FFFFFF",
            fontWeight: 500,
            paddingRight: "30px",
          }}
          className={"textPlaceholder"}
          autoComplete={"off"}
        />
        {type === "password" && (
          <div
            onClick={togglePasswordVisibility}
            style={{
              position: "absolute",
              right: "10px",
              top: "50%",
              transform: "translateY(-50%)",
              cursor: "pointer",
            }}
          >
            {showPassword ? (
              <i className={"fa fa-eye-slash"} />
            ) : (
              <i className={"fa fa-eye"} />
            )}
          </div>
        )}
      </div>
    </div>
  );
}

function SelectField({
  name,
  value,
  handleChange,
  options,
  placeholder,
  ...rest
}) {
  return (
    <div className="col-md-6">
      <div className="form-grp">
        <select
          name={name}
          value={value}
          onChange={handleChange}
          style={{
            background: "#FFFFFF",
          }}
          autoComplete={"off"}
          {...rest}
        >
          <option value="">{placeholder}</option>
          {options.map((opt, i) => (
            <option key={i} value={opt.id}>
              {opt.name}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}

export default ErmRegistration;
