import React from "react";
import { Routes, Route } from "react-router-dom";

// Home
import Home from "../views/Home";

// About Company
import About from "../views/About";
import Founder from "../views/Founder";
import Certification from "../views/Certification";

// Offer Plan
import OfferPlan from "../views/OfferPlan";

// Downloads
import Downloads from "../views/Downloads";

// Contact
import Contact from "../views/Contact";

// Footer Links
import Disclaimer from "../views/Disclaimer";
import Policy from "../views/Policy";

// Registration
import EmcRegistration from "../views/EmcRegistration";
import ErmRegistration from "../views/ErmRegistration";

// FAQ
import FAQ from "../views/FAQ";

// Not Found
import NotFound from "../views/NotFound";

function PageRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path="/founder" element={<Founder />} />
      <Route path="/certification" element={<Certification />} />
      <Route path="/offer-plan/:slug" element={<OfferPlan />} />
      <Route path="/downloads" element={<Downloads />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/emc-registration" element={<EmcRegistration />} />
      <Route path="/erm-registration" element={<ErmRegistration />} />
      <Route path="/disclaimer" element={<Disclaimer />} />
      <Route path="/policy/:slug" element={<Policy />} />
      <Route path="/faq" element={<FAQ />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default PageRoutes;
