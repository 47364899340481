import { Link, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { BaseUrl, PDF_FILE } from "../Constant/BaseUrl";

const OfferSidebar = () => {
  const { slug } = useParams();
  const [offer, setOffer] = useState([]);

  const getOfferPlan = async () => {
    try {
      const response = await axios.get(`${BaseUrl}category/PLAN`);
      setOffer(response.data.data || []);
    } catch (error) {}
  };

  useEffect(() => {
    getOfferPlan();
  }, []);

  return (
    <aside className="services__sidebar">
      <div className="sidebar__widget sidebar__widget-three d-md-block d-none">
        <div className="sidebar__cat-list-two sidebar__cat-list-three">
          <ul className="list-wrap">
            {offer?.map((val, index) => (
              <li key={index}>
                <Link
                  to={"/offer-plan/" + val.slug}
                  className={slug === val.slug ? "offerSidebarActive" : ""}
                >
                  {val.name} <i className="flaticon-arrow-button"></i>
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="sidebar__widget sidebar__widget-two">
        <div className="sidebar__contact sidebar__contact-two sidebar_head_contact">
          <h2 className="title">If You Need Any Help Contact With Us</h2>
          <Link to="tel:0123456789" className="btn">
            <i className="flaticon-phone-call"></i>
            +91-9971713967
          </Link>
        </div>
      </div>
      <div className="sidebar__widget sidebar__widget-three">
        <h4 className="sidebar__widget-title m-0">Brochure</h4>
        <div className="sidebar__brochure sidebar__brochure-two">
          <p className={"text-16-semibold"}>Abhhyam Secured Life </p>
          <Link to={PDF_FILE} target="_blank">
            <i className="far fa-file-pdf"></i>PDF Download
          </Link>
        </div>
      </div>
    </aside>
  );
};

export default OfferSidebar;
