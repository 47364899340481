import React from "react";
import RequestCall from "../components/Requestcall";
import WebLayout from "../layout/WebLayout";
import { Link } from "react-router-dom";

function Founder() {
  return (
    <WebLayout>
      <>
        <main className="fix">
          <section className="breadcrumb__area breadcrumb__bg about_banner_bg">
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <div className="breadcrumb__content">
                    <h2 className="title">Founder Team</h2>
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                          <Link to="/">Home</Link>
                        </li>
                        <li
                          className="breadcrumb-item active"
                          aria-current="page"
                        >
                          Founder Team
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="team__area-four">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-6">
                  <div className="section-title text-center mb-40">
                    <span className="sub-title">ASL TEAM</span>
                    <h2 className="title">
                      Team Behind the <br /> Abhhyam Secured Life
                    </h2>
                  </div>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-8">
                  <div className="team__item-four shine-animate-item">
                    <div className="team__thumb-four shine-animate">
                      <img src={"./assets/img/team/team_img01.jpg"} alt="" />
                    </div>
                    <div className="team__content-four">
                      <h2 className="title">
                        <Link to="#">Puran Chand Arya, (Retd) CRPF</Link>
                      </h2>
                      <span>Founder Chairman</span>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-8">
                  <div className="team__item-four shine-animate-item">
                    <div className="team__thumb-four shine-animate">
                      <img src={"./assets/img/team/team_img02.jpg"} alt="" />
                    </div>
                    <div className="team__content-four">
                      <h2 className="title">
                        <Link to="#">Er. Navin Kumar Sah</Link>
                      </h2>
                      <span>Managing Director</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <RequestCall />
        </main>
      </>
    </WebLayout>
  );
}

export default Founder;
