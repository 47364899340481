import React from "react";
import RequestCall from "../components/Requestcall";
import WebLayout from "../layout/WebLayout";
import { Link } from "react-router-dom";

function Disclaimer() {
  return (
    <WebLayout>
      <main className="fix">
        <section className="breadcrumb__area breadcrumb__bg about_banner_bg">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="breadcrumb__content">
                  <h2 className="title">Disclaimer</h2>
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="#">Home</Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        Disclaimer
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="about__area-four">
          <div className="container">
            <div className="row align-items-center justify-content-center">
              <div className="col-lg-12">
                <div className="">
                  <div className="section-title mb-10">
                    <h4>Disclaimer for www.AbhhyamSecure.com</h4>
                  </div>
                  <p>
                    Welcome to www.AbhhyamSecure.com ("Website"), operated by
                    Abhhyam Secure Private Limited. By accessing or using this
                    Website, you agree to comply with and be bound by the
                    following disclaimer. If you do not agree, please refrain
                    from using this Website.
                  </p>
                  <div className="section-title">
                    <h5>General Information</h5>
                  </div>
                  <div className="about__list-box">
                    <ul>
                      <li>
                        The information provided on this Website is for general
                        informational purposes only and is subject to change
                        without prior notice. While we strive to keep the
                        information accurate and up-to-date, we make no
                        warranties or guarantees about the completeness,
                        reliability, or accuracy of the content.
                      </li>
                    </ul>
                  </div>
                  <div className="section-title">
                    <h5>No Guarantee of Benefits</h5>
                  </div>
                  <div className="about__list-box">
                    <ul>
                      <li>
                        Participation in the Abhhyam Secured Life (ASL) plan
                        does not guarantee incentives, pensionary benefits, or
                        other service-related benefits. The realization of such
                        benefits depends on various factors, including but not
                        limited to compliance with ASL policies, regular service
                        usage, employee tenure, and employer participation.
                      </li>
                      <li>
                        All examples and calculations provided on the Website
                        are illustrative and do not ensure specific outcomes.
                        Actual benefits and incentives may vary based on
                        individual and organizational performance.
                      </li>
                    </ul>
                  </div>
                  <div className="section-title">
                    <h5>Third-Party Services and Compliance</h5>
                  </div>
                  <div className="about__list-box">
                    <ul>
                      <li>
                        The ASL plan includes services provided by third-party
                        entities, such as insurance companies. Abhhyam Secure
                        Private Limited does not assume responsibility for the
                        accuracy, reliability, or availability of services
                        provided by third parties.
                      </li>
                      <li>
                        All participants, including Entity Member Companies
                        (EMCs), Entity Member Accounts (EMAs), and Entity
                        Representative Members (ERMs), are responsible for
                        adhering to applicable laws, including tax obligations.
                      </li>
                    </ul>
                  </div>

                  <div className="section-title">
                    <h5>Limitation of Liability</h5>
                  </div>
                  <div className="about__list-box">
                    <ul>
                      <li>
                        Abhhyam Secure Private Limited shall not be liable for
                        any direct, indirect, incidental, consequential, or
                        punitive damages arising out of or related to the use of
                        this Website, participation in the ASL plan, or reliance
                        on its content.
                      </li>
                      <li>
                        The company reserves the right to amend or modify the
                        terms, policies, and benefits of the ASL plan without
                        prior notice.
                      </li>
                    </ul>
                  </div>

                  <div className="section-title">
                    <h5>User Responsibilities</h5>
                  </div>
                  <div className="about__list-box">
                    <ul>
                      <li>
                        Users are required to ensure the accuracy and
                        completeness of the information they provide during
                        registration or any interaction with the ASL platform.
                      </li>
                      <li>
                        EMCs and ERMs must ensure active participation in the
                        ASL plan to remain eligible for benefits.
                      </li>
                      <li>
                        Failure to meet the terms and conditions outlined in the
                        ASL policy may result in suspension or termination of
                        benefits.
                      </li>
                    </ul>
                  </div>

                  <div className="section-title">
                    <h5>Jurisdiction</h5>
                  </div>
                  <div className="about__list-box">
                    <ul>
                      <li>
                        All disputes arising from the use of this Website or
                        participation in the ASL plan will be governed by the
                        laws of India and subject to the jurisdiction of courts
                        in Delhi, India.
                      </li>
                    </ul>
                  </div>

                  <div className="section-title">
                    <h5>Updates to the Disclaimer</h5>
                  </div>
                  <div className="about__list-box">
                    <ul>
                      <li>
                        Abhhyam Secure Private Limited reserves the right to
                        update or modify this disclaimer at any time without
                        prior notice. Users are encouraged to review this page
                        periodically to stay informed of any changes.
                      </li>
                    </ul>
                  </div>

                  <div className="about__list-box">
                    <p>
                      For additional information or queries, please contact us
                      at:
                    </p>
                    <ul>
                      <li>
                        <b>Email:</b> care@abhhyamsecure.com
                      </li>
                      <li>
                        <b>Phone:</b> +91-9971 713 967
                      </li>
                    </ul>
                    <p>Thank you for visiting www.AbhhyamSecure.com.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <RequestCall />
      </main>
    </WebLayout>
  );
}

export default Disclaimer;
