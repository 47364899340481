import React from "react";
import RequestCall from "../components/Requestcall";
import WebLayout from "../layout/WebLayout";
import { Link } from "react-router-dom";

function About() {
  return (
    <WebLayout>
      <>
        <main className="fix">
          <section className="breadcrumb__area breadcrumb__bg about_banner_bg">
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <div className="breadcrumb__content">
                    <h2 className="title">About ASL</h2>
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                          <Link to="#">Home</Link>
                        </li>
                        <li
                          className="breadcrumb-item active"
                          aria-current="page"
                        >
                          About ASL
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="about__area-four">
            <div className="container">
              <div className="row align-items-center justify-content-center">
                <div className="col-lg-12">
                  <div className="">
                    <div className="section-title mb-10">
                      <h2 className="title">
                        Brief About Abhhyam Secured Life (ASL) Scheme:
                      </h2>
                    </div>
                    <p>
                      Abhhyam Secured Life (ASL) is a comprehensive initiative
                      designed to support and secure the lives of employees in
                      India, with the ultimate aim of fostering a secure nation.
                      The scheme initiative by Abhhyam Secure Private Limited
                      (ASPL), focuses on enhancing employee welfare by providing
                      a wide range of benefits, including pensionary plans,
                      insurance, and other service benefits, which are intended
                      to secure the future of employees and contribute to
                      organizational stability.
                    </p>
                    <div className="section-title mb-10">
                      <h2 className="title">Key Objectives:</h2>
                    </div>
                    <div className="about__content-inner-three">
                      <div className="about__list-box">
                        <ul className="list-wrap">
                          <li>
                            <i className="flaticon-arrow-button"></i> Employee
                            Welfare and Security: ASL aims to ensure the
                            well-being, safety, and security of employees by
                            offering lifetime pension bonds, insurance, and
                            other benefits, which contribute to both employee
                            retention and satisfaction.
                          </li>
                          <li>
                            <i className="flaticon-arrow-button"></i> Support
                            for Employers: The scheme offers a variety of
                            services to employers, including HR management,
                            payroll services, compliance with labor laws, and
                            financial incentives. This support helps employers
                            create a nurturing work environment, retain talent,
                            and secure the long-term future of their workforce.
                          </li>
                          <li>
                            <i className="flaticon-arrow-button"></i> The
                            ultimate Direct Selling Marketing Plan: ASL employs
                            ultimate unique direct selling marketing model that
                            allows employers to earn incentives through the
                            purchase of HR and payroll services. This model is
                            designed to create a sustainable income stream for
                            employers while also generating pensionary and other
                            service benefits for employees.
                          </li>
                          <li>
                            <i className="flaticon-arrow-button"></i>
                            Pensionary and Service Benefits: The scheme provides
                            a structured plan where employees can earn
                            pensionary benefits after a minimum of 96 months of
                            service. These benefits include pension bonds,
                            gratuity, and leave bonuses, education scholarship,
                            Family Travel Incentive which are designed to
                            provide financial security during the service and
                            after retirement.
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="section-title mb-10">
                      <h2 className="title">Advantages of ASL:</h2>
                    </div>
                    <div className="about__content-inner-three">
                      <div className="about__list-box">
                        <ul className="list-wrap">
                          <li>
                            <i className="flaticon-arrow-button"></i> For
                            Employers: ASL offers attractive monthly incentives,
                            comprehensive HR and payroll services, and guidance
                            on legal and technological matters. Employers also
                            benefit from enhanced employee retention and reduced
                            turnover costs.
                          </li>
                          <li>
                            <i className="flaticon-arrow-button"></i> For
                            Employees: The scheme provides long-term financial
                            security through pension bonds, insurance coverage,
                            and various other pensionary and service benefits.
                            It also promotes work-life balance and supports
                            employee growth and satisfaction.
                          </li>
                        </ul>
                      </div>
                    </div>
                    <p>
                      Scope of Services: ASL's services are wide-ranging and
                      include online HR management, payroll processing,
                      compliance with labor laws, and disbursement of salaries.
                      The scheme also offers educational scholarships for
                      employees' children, gratuity payments, and digital tools
                      to manage employee records.
                    </p>
                    <p>
                      Conclusion: Abhhyam Secured Life is an innovative and
                      holistic approach to employee welfare and organizational
                      stability. By integrating financial security measures with
                      comprehensive HR services, ASL not only supports the
                      well-being of employees but also contributes to the
                      long-term success and security of organizations.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <RequestCall />
        </main>
      </>
    </WebLayout>
  );
}

export default About;
