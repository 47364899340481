import React, { useRef } from "react";
import { useEffect, useState } from "react";
import WebLayout from "../layout/WebLayout";
import { Link } from "react-router-dom";
import axios from "axios";
import { BaseUrl, EMC_PDF } from "../Constant/BaseUrl";
import TermsAndModal from "../components/TermsAndModal";
import PrivacyModal from "../components/PrivacyModal";
import SearchSelect from "../components/SearchSelect";
import RegistrationMessage from "../components/RegistrationMessage";

const HowToRegister = () => {
  const openPdf = () => {
    window.open(EMC_PDF, "_blank", "width=800,height=600");
  };

  return (
    <div className="how-to-register">
      <h2
        className="text-2xl font-bold"
        style={{
          background: "#f7a400",
          alignSelf: "center",
          padding: 10,
          color: "#FFFFFF",
        }}
      >
        How to Register?
      </h2>
      <div className="mt-6">
        <button onClick={openPdf} className="btn btn-primary">
          📄 Check Registration Process
        </button>
      </div>
    </div>
  );
};

function EmcRegistration() {
  const errorRef = useRef(null);

  const [options, setOptions] = useState([]);
  const [formData, setFormData] = useState({
    guideId: "",
    companyName: "",
    employerName: "",
    noOfEmployee: "",
    email: "",
    phone: "",
    state: "",
    district: "",
    pinCode: "",
    typeOfCompany: "",
  });

  const [stateData, setStateData] = useState([]);
  const [districtData, setDistrictData] = useState([]);
  const [companyTypeData, setCompanyTypeData] = useState([]);

  const [termsModal, setTermsModal] = useState(false);
  const [privacyModal, setPrivacyModal] = useState(false);

  const [apiMessage, setApiMessage] = useState({
    type: "",
    message: "",
  });

  const [message, setMessage] = useState({
    display: false,
    email: "",
    password: "",
  });

  const [query, setQuery] = useState("");

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "phone") {
      const numericValue = value.replace(/[^0-9]/g, ""); // Allow only numbers
      setFormData((prevState) => ({ ...prevState, [name]: numericValue }));
    } else if (name === "noOfEmployee") {
      const numericValue = value.replace(/[^0-9]/g, ""); // Allow only numbers
      setFormData((prevState) => ({ ...prevState, [name]: numericValue }));
    } else if (name === "pinCode") {
      const numericValue = value.replace(/[^0-9]/g, ""); // Allow only numbers
      setFormData((prevState) => ({ ...prevState, [name]: numericValue }));
    } else {
      setFormData((prevState) => ({ ...prevState, [name]: value }));
    }
  };

  // Submit form data
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        `${BaseUrl}create-employer`,
        JSON.stringify(formData),
        { headers: { "Content-Type": "application/json" } },
      );

      if (response.data.status) {
        setMessage({
          display: true,
          email: formData.email,
          password: formData.phone,
        });
        resetForm();
      } else {
        displayApiMessage("error_message", response.data.msg);
      }
    } catch (error) {
      const errorMessage =
        error.response?.data?.msg ||
        "An error occurred while submitting the form.";
      displayApiMessage("error_message", errorMessage);
    }
  };

  const resetForm = () => {
    setFormData({
      guideId: "",
      companyName: "",
      employerName: "",
      noOfEmployee: "",
      email: "",
      phone: "",
      state: "",
      district: "",
      pinCode: "",
      typeOfCompany: "",
    });
    setDistrictData([]);
    setQuery("");
  };

  const displayApiMessage = (type, message) => {
    setApiMessage({ type, message });

    // Scroll to error message when an error occurs
    setTimeout(() => {
      if (errorRef.current) {
        errorRef.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    }, 100);

    // Hide message after 3 seconds
    setTimeout(() => setApiMessage({ type: "", message: "" }), 3000);
  };

  // Fetch state list
  const fetchStateData = async () => {
    try {
      const response = await axios.get(`${BaseUrl}state`);
      setStateData(response.data.data || []);
    } catch (error) {}
  };

  const fetchDistrictData = async (state_id) => {
    if (!state_id) return setDistrictData([]);
    try {
      const response = await axios.post(
        `${BaseUrl}district`,
        JSON.stringify({ state_id }),
        { headers: { "Content-Type": "application/json" } },
      );
      setDistrictData(response.data.data || []);
    } catch (error) {}
  };

  const getCompanyTypeList = async () => {
    try {
      const response = await axios.get(`${BaseUrl}get-company-type`);
      setCompanyTypeData(response.data.data);
    } catch (error) {}
  };

  const handleERM = () => {
    setQuery("ERM");
  };

  useEffect(() => {
    fetchStateData();
    getCompanyTypeList();
  }, []);

  useEffect(() => {
    fetchDistrictData(formData.state);
  }, [formData.state]);

  return (
    <WebLayout>
      <main>
        <section
          className="breadcrumb__area breadcrumb__bg"
          style={{ padding: 20 }}
        >
          <div className="container">
            {message.display ? (
              <div className={"row"}>
                <RegistrationMessage
                  displayMessage={`<style> h1 { color: #4a4a4a; margin-bottom: 10px; } p { font-size: 16px; color: #4a4a4a; margin-bottom: 20px; } .credentials { background-color: #f9f9f9; padding: 20px; border: 2px dashed #6e8efb; border-radius: 10px; margin: 20px 0; } .credentials { display: block; margin: 10px 0; font-weight: bold; color: #333; } .login-btn { background: linear-gradient(135deg, #6e8efb, #a777e3); color: white; padding: 15px 25px; border: none; border-radius: 8px; text-decoration: none; font-size: 16px; font-weight: bold; cursor: pointer; transition: background 0.3s ease, transform 0.2s ease; display: inline-block; } .login-btn:hover { background: linear-gradient(135deg, #5d7ae3, #9163d4); transform: scale(1.05); } </style><div class="container"> <h1>Welcome to Abhhyam Secured Life!</h1> <p><strong>Let's offer a secure life to each and every entity.</strong></p> <div class="credentials"> <p><strong>YOUR LOGIN CREDENTIALS</strong></p> <p><strong>Email: ${message.email}</></p> <p class="mb-0"><strong>Password: ${message.password}</strong></p> </div> <a href="https://emc.abhhyamsecure.com/" target="_blank" class="login-btn">👉 Click Here to Login</a> </div>`}
                />
              </div>
            ) : (
              <div className="row">
                <TermsAndModal
                  termsModal={termsModal}
                  setTermsModal={setTermsModal}
                />
                <PrivacyModal
                  privacyModal={privacyModal}
                  setPrivacyModal={setPrivacyModal}
                />
                <div
                  className={"col-lg-12 mb-3"}
                  style={{
                    background: "black",
                  }}
                >
                  <h1 className="title text-center m-0 text-white registrationHeading">
                    ENTITY REGISTRATION
                  </h1>
                  <p
                    className={
                      " text-center m-0 text-white registrationSubHeading"
                    }
                  >
                    Entity Member Company (EMC)
                  </p>
                </div>
                <div className="col-lg-7">
                  <p className={"contentHeading"}>
                    <span
                      style={{
                        fontWeight: 800,
                      }}
                    >
                      Any legal company / institutions / organizations are
                      eligible to be registered as Entity Member Company (EMC)
                      to get the services offered by the Abhhyam Secured Life
                      (ASL).
                    </span>
                  </p>
                  <ul style={{ listStyle: "decimal" }}>
                    <li>
                      No registration fees, can submit request proposal at&nbsp;
                      <Link to={"/"}>
                        <b style={{ color: "#3e4073" }}>
                          www.AbhhyamSecure.com
                        </b>
                      </Link>{" "}
                      for process.
                    </li>
                    <li>
                      On the first invoice of the HRM services contract from
                      ASL, the ASL ID Account number will be allocated for each
                      number of employee account created by the Entity Member
                      Company (EMC).
                    </li>
                    <li>
                      The Entity Member Company (EMC) has to declare its total
                      number of employees at the time of registration to create
                      an equal number of ASL ID Accounts for each employee to
                      facilitate Pensionary and other services benefits.
                    </li>
                    <li>All process is digitally designed.</li>
                  </ul>
                  <HowToRegister />
                </div>
                <div className="col-lg-5">
                  {apiMessage.message && (
                    <p ref={errorRef} className={`${apiMessage.type}`}>
                      {apiMessage.message}
                    </p>
                  )}
                  <div
                    className="contact__form-wrap"
                    style={{ margin: 0, background: "wheat" }}
                  >
                    <form id="registrationForm" onSubmit={handleSubmit}>
                      <div className="row">
                        <InputField
                          name="companyName"
                          value={formData.companyName}
                          handleChange={handleInputChange}
                          placeholder="Entity Name *"
                          required
                        />
                        <SelectField
                          name="typeOfCompany"
                          value={formData.typeOfCompany}
                          handleChange={handleInputChange}
                          options={companyTypeData}
                          placeholder="Select Type of Company"
                          required
                          column={"col-md-6"}
                        />
                        <InputField
                          name="employerName"
                          value={formData.employerName}
                          handleChange={handleInputChange}
                          placeholder="Manager Name *"
                          required
                        />
                        <InputField
                          name="noOfEmployee"
                          value={formData.noOfEmployee}
                          handleChange={handleInputChange}
                          placeholder="No. of Employee *"
                          type="noOfEmployee"
                          required
                        />
                        <InputField
                          name="email"
                          value={formData.email}
                          handleChange={handleInputChange}
                          placeholder="Email *"
                          type="email"
                          required
                        />
                        <InputField
                          name="phone"
                          value={formData.phone}
                          handleChange={handleInputChange}
                          placeholder="Phone *"
                          type="phone"
                          maxLength={10}
                          minLength={10}
                          required
                        />

                        <SelectField
                          name="state"
                          value={formData.state}
                          handleChange={handleInputChange}
                          options={stateData}
                          placeholder="Select State *"
                          required
                        />
                        <SelectField
                          name="district"
                          value={formData.district}
                          handleChange={handleInputChange}
                          options={districtData}
                          placeholder="Select District *"
                          required
                        />
                        <InputField
                          name="pinCode"
                          value={formData.pinCode}
                          handleChange={handleInputChange}
                          placeholder="Pincode *"
                          maxLength={6}
                          required
                        />
                        <div className={"col-md-6"}>
                          <SearchSelect
                            setGuideId={(value) =>
                              setFormData((prevState) => ({
                                ...prevState,
                                guideId: value,
                              }))
                            }
                            options={options}
                            setOptions={setOptions}
                            setQuery={setQuery}
                            query={query}
                            onPress={handleERM}
                          />
                        </div>
                      </div>
                      <div className="form-grp checkbox-grp">
                        <input
                          type="checkbox"
                          id="termsCheckbox"
                          checked
                          readOnly
                        />
                        <label style={{ fontWeight: "bold" }}>
                          I accept{" "}
                          <Link
                            to={"#"}
                            onClick={() => setTermsModal(true)}
                            style={{ fontWeight: "bold", color: "#3913ff" }}
                          >
                            Terms & Conditions
                          </Link>{" "}
                          and{" "}
                          <Link
                            to={"#"}
                            onClick={() => setPrivacyModal(true)}
                            style={{ fontWeight: "bold", color: "#3913ff" }}
                          >
                            Privacy Policy
                          </Link>
                        </label>
                      </div>
                      <div className="submit-button text-center">
                        <button type="submit" className="registerBtn">
                          REGISTER
                        </button>
                        <p className={"m-2"}>
                          <b>OR</b>
                        </p>
                        <Link
                          to={"https://emc.abhhyamsecure.com/"}
                          target={"_blank"}
                        >
                          <button type="button" className="loginBtn">
                            LOGIN
                          </button>
                        </Link>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            )}
          </div>
        </section>
      </main>
    </WebLayout>
  );
}

function InputField({
  type,
  name,
  value,
  handleChange,
  placeholder,
  column = "col-md-6",
  ...rest
}) {
  const [showPassword, setShowPassword] = useState(false);

  // Toggle the password visibility
  const togglePasswordVisibility = () => setShowPassword((prev) => !prev);

  return (
    <div className={column}>
      <div className="form-grp" style={{ position: "relative" }}>
        <input
          type={
            type === "password" && !showPassword
              ? "password"
              : type === "email"
                ? "email"
                : "text"
          }
          name={name}
          value={value}
          onChange={handleChange}
          placeholder={placeholder}
          {...rest}
          style={{
            background: "#FFFFFF",
            fontWeight: 500,
            paddingRight: "30px",
          }}
          className={"textPlaceholder"}
          autoComplete={"off"}
        />
        {type === "password" && (
          <div
            onClick={togglePasswordVisibility}
            style={{
              position: "absolute",
              right: "10px",
              top: "50%",
              transform: "translateY(-50%)",
              cursor: "pointer",
            }}
          >
            {showPassword ? (
              <i className={"fa fa-eye-slash"} />
            ) : (
              <i className={"fa fa-eye"} />
            )}
          </div>
        )}
      </div>
    </div>
  );
}

function SelectField({
  name,
  value,
  handleChange,
  options,
  placeholder,
  column = "col-md-6",
  ...rest
}) {
  return (
    <div className={column}>
      <div className="form-grp">
        <select
          name={name}
          value={value}
          onChange={handleChange}
          style={{
            background: "#FFFFFF",
          }}
          autoComplete={"off"}
          {...rest}
        >
          <option value="">{placeholder}</option>
          {options.map((opt, i) => (
            <option key={i} value={opt.id}>
              {opt.name}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}

export default EmcRegistration;
