import React from "react";
import RequestCall from "../components/Requestcall";
import WebLayout from "../layout/WebLayout";
import { Link } from "react-router-dom";

function Home() {
  return (
    <WebLayout>
      <main className="fix">
        <section className="banner-area banner-bg" style={{ minHeight: 0 }}>
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="banner-content">
                  <span className="sub-title">
                    Aim is Secure life of employee
                  </span>
                  <h2 className="title text-white">
                    Let’s create a secure and thriving environment
                  </h2>
                  <p className="text-white">
                    Fostering a workplace culture dedicated to securing the life
                    of every employee, where holistic well-being, professional
                    growth, and comprehensive benefits converge to create a
                    thriving and secure future for all.
                  </p>
                  <Link className="btn" to="/about">
                    Read More
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="about" className="about-area pt-60 pb-60">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="about-img-wrap">
                  <div className="mask-img-wrap">
                    <img src={"./assets/img/images/about_img01.jpg"} alt="" />
                  </div>
                  <div className="shape">
                    <img src={"./assets/img/images/about_shape01.png"} alt="" />
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="about-content">
                  <div className="section-title mb-35 tg-heading-subheading animation-style3">
                    <span className="sub-title">
                      Secure Citizen, Secure India
                    </span>
                    <h2 className="title tg-element-title">
                      We Help Organizations To <br />
                      grow along with each employee secure life
                    </h2>
                  </div>
                  <div className="about-list">
                    <ul className="list-wrap">
                      <li>
                        <div className="icon">
                          <i className="flaticon-target"></i>
                        </div>
                        <div className="content">
                          <h4 className="title">Growing Business</h4>
                          <p>Secured life solutions for employees</p>
                        </div>
                      </li>
                      <li>
                        <div className="icon">
                          <i className="flaticon-profit"></i>
                        </div>
                        <div className="content">
                          <h4 className="title">Secured Employee</h4>
                          <p>Dedicated contribution for business growth</p>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <p>
                    Ensuring the well-being and safety of every employee,
                    fostering a secure and thriving work environment.
                  </p>
                  <div className="about-bottom">
                    <div className="author-wrap">
                      <div className="thumb">
                        <img
                          src={"./assets/img/images/author_img.png"}
                          alt=""
                        />
                      </div>
                      <div className="content">
                        <h4 className="title">
                          Puran Chand Arya <span>, Founder Chairman</span>
                        </h4>
                      </div>
                    </div>
                    {/*<Link className="btn btn-two" to="#">*/}
                    {/*  Read More*/}
                    {/*</Link>*/}
                  </div>
                  <div className="about-shape-wrap">
                    <img src={"./assets/img/images/about_shape03.png"} alt="" />
                    <img
                      src={"./assets/img/images/about_shape04.png"}
                      alt=""
                      className="ribbonRotate"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="about-left-shape">
            <img src={"./assets/img/images/about_shape02.png"} alt="" />
          </div>
        </section>

        <section className="services-area services-bg position-relative">
          <div
            className="bg-img position-absolute top-0 bottom-0 start-0 end-0 z-0"
            data-background={"./assets/img/bg/services_bg.jpg"}
            style={{
              backgroundImage: "url(./assets/img/bg/services_bg.jpg)",
            }}
          />
          <div className="container position-relative z-1">
            <div className="row justify-content-center">
              <div className="col-xl-6 col-lg-8">
                <div className="section-title text-center mb-40 tg-heading-subheading animation-style3">
                  <span className="sub-title">WE OFFER SECURED LIFE</span>
                  <h2 className="title tg-element-title">
                    We offer HRM Services Solutions for business growth with
                    Secured Life options
                  </h2>
                </div>
              </div>
            </div>
            <div className="services-item-wrap">
              <div className="row justify-content-center">
                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-8">
                  <div className="services-item shine-animate-item">
                    <div className="services-thumb">
                      <Link className="shine-animate" to="#">
                        <img
                          src={"./assets/img/services/services_img01.jpg"}
                          alt=""
                        />
                      </Link>
                    </div>
                    <div className="services-content">
                      <div className="icon">
                        <i className="flaticon-profit"></i>
                      </div>
                      <h4 className="title">
                        <Link to="#">SCOPE OF SERVICES AND SUPPORT</Link>
                      </h4>
                      <p>
                        The plan and benefits are proposed through Abhhyam
                        Secure Life (ASL) are as under...
                      </p>
                      {/*<Link className="btn" to="#">*/}
                      {/*  Read More*/}
                      {/*</Link>*/}
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-8">
                  <div className="services-item shine-animate-item">
                    <div className="services-thumb">
                      <Link className="shine-animate" to="#">
                        <img
                          src={"./assets/img/services/services_img02.jpg"}
                          alt=""
                        />
                      </Link>
                    </div>
                    <div className="services-content">
                      <div className="icon">
                        <i className="flaticon-investment-1"></i>
                      </div>
                      <h4 className="title">
                        <Link to="#">
                          ADVANTAGES Abhhyam Secured Life (ASL)
                        </Link>
                      </h4>
                      <p>
                        Abhhyam Secured Life (ASL) is designed and planned to
                        generate the following advantages...
                      </p>
                      {/*<Link className="btn" to="#">*/}
                      {/*  Read More*/}
                      {/*</Link>*/}
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-8">
                  <div className="services-item shine-animate-item">
                    <div className="services-thumb">
                      <Link className="shine-animate" to="#">
                        <img
                          src={"./assets/img/services/services_img03.jpg"}
                          alt=""
                        />
                      </Link>
                    </div>
                    <div className="services-content">
                      <div className="icon">
                        <i className="flaticon-pie-chart"></i>
                      </div>
                      <h4 className="title">
                        <Link to="#">PENSIONARY BENEFITS FOR EMPLOYEES</Link>
                      </h4>
                      <p>
                        Abhhyam Secured Life (ASL) offers pensionary and other
                        benefits for employees through...
                      </p>
                      {/*<Link className="btn" to="#">*/}
                      {/*  Read More*/}
                      {/*</Link>*/}
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-8">
                  <div className="services-item shine-animate-item">
                    <div className="services-thumb">
                      <Link className="shine-animate" to="#">
                        <img
                          src={"./assets/img/services/services_img04.jpg"}
                          alt=""
                        />
                      </Link>
                    </div>
                    <div className="services-content">
                      <div className="icon">
                        <i className="flaticon-light-bulb"></i>
                      </div>
                      <h4 className="title">
                        <Link to="#">
                          INITIATIVE: “CITIZEN SECURE – COUNTRY SECURE”
                        </Link>
                      </h4>
                      <p>
                        Ensuring the security of citizens requires a holistic
                        approach that addresses their basic needs across
                        multiple dimensions...
                      </p>
                      {/*<Link className="btn" to="#">*/}
                      {/*  Read More*/}
                      {/*</Link>*/}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="choose-area">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 order-0 order-lg-2">
                <div className="choose-img-wrap">
                  <img src={"./assets/img/images/choose_img01.jpg"} alt="" />
                  <img
                    src={"./assets/img/images/choose_img02.jpg"}
                    alt=""
                    data-parallax='{"x" : 50 }'
                  />
                  <img
                    src={"./assets/img/images/choose_img_shape.png"}
                    alt=""
                    className="alltuchtopdown"
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="choose-content">
                  <div className="section-title white-title mb-30 tg-heading-subheading animation-style3">
                    <span className="sub-title">
                      Citizen Secure-Country secure
                    </span>
                    <h2 className="title tg-element-title">
                      Employee welfare and care <br /> is a cornerstone of
                      organizational success.
                    </h2>
                  </div>
                  <p>
                    Employee welfare and care is a cornerstone of organizational
                    success. When organizations invest in the well-being of
                    their employees, they create a workplace where employees are
                    satisfied, motivated, and committed to achieving the
                    organization's goals. Employee welfare is not just an
                    ethical obligation; it is a strategic advantage that
                    positively impacts an organization's performance, culture,
                    and reputation. As organizations continue to evolve, they
                    must recognize that the heart of their success lies in
                    nurturing the well-being of their employees. By investing in
                    employees' well-being and providing the necessary support,
                    organizations can create a positive and productive work
                    environment, improve job satisfaction, and ultimately
                    achieve be􀆩er business results. Employee care is a win-win,
                    benefiting both employees and the organization. If employee
                    happy, the company is happy, if employee secure, the company
                    secure or we can say If citizen is secured, the country will
                    be secured.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="choose-shape-wrap">
            <img
              src={"./assets/img/images/choose_shape01.png"}
              alt=""
              data-aos="fade-right"
              data-aos-delay="400"
            />
            <img
              src={"./assets/img/images/choose_shape02.png"}
              alt=""
              data-aos="fade-left"
              data-aos-delay="400"
            />
          </div>
        </section>

        <section className="counter-area">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-3 col-lg-4 col-sm-6">
                <div className="counter-item">
                  <div className="icon">
                    <i className="flaticon-trophy"></i>
                  </div>
                  <div className="content">
                    <h2 className="count">
                      <span className="odometer" data-count="45"></span>
                    </h2>
                    <p>EMC – (Entity Member Company Registered)</p>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-6">
                <div className="counter-item">
                  <div className="icon">
                    <i className="flaticon-happy"></i>
                  </div>
                  <div className="content">
                    <h2 className="count">
                      <span className="odometer" data-count="92"></span>
                    </h2>
                    <p>ERM (Entity Rep Member)</p>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-6">
                <div className="counter-item">
                  <div className="icon">
                    <i className="flaticon-china"></i>
                  </div>
                  <div className="content">
                    <h2 className="count">
                      <span className="odometer" data-count="19"></span>
                    </h2>
                    <p>District wise presence</p>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-6">
                <div className="counter-item">
                  <div className="icon">
                    <i className="flaticon-time"></i>
                  </div>
                  <div className="content">
                    <h2 className="count">
                      <span className="odometer" data-count="25"></span>
                    </h2>
                    <p>Active ASL Account</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="counter-shape-wrap">
            <img
              src={"./assets/img/images/counter_shape01.png"}
              alt=""
              data-aos="fade-right"
              data-aos-delay="400"
            />
            <img
              src={"./assets/img/images/counter_shape02.png"}
              alt=""
              data-parallax='{"x" : 100 , "y" : -100 }'
            />
            <img
              src={"./assets/img/images/counter_shape03.png"}
              alt=""
              data-aos="fade-left"
              data-aos-delay="400"
            />
          </div>
        </section>

        <section
          className="request-area request-bg"
          data-background={"./assets/img/bg/services_bg.jpg"}
          style={{
            backgroundImage: "url(./assets/img/bg/request_bg.jpg)",
          }}
        >
          <div className="bg-img position-absolute top-0 bottom-0 start-0 end-0 z-0" />
          <div className="container position-relative z-1">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <div className="request-content text-center tg-heading-subheading animation-style3">
                  <h2 className="title tg-element-title">
                    Offering The Best Experience Of Business Consulting Services
                  </h2>
                  <div className="content-bottom">
                    <Link to="tel:9971713967" className="btn">
                      Request a Free Call
                    </Link>
                    <div className="content-right">
                      <div className="icon">
                        <i className="flaticon-phone-call"></i>
                      </div>
                      <div className="content">
                        <span>Toll Free Call</span>
                        <Link to="tel:9971713967">+91-9971713967</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="request-shape">
            <img
              src={"./assets/img/images/request_shape01.png"}
              alt=""
              data-aos="fade-right"
              data-aos-delay="400"
            />
            <img
              src={"./assets/img/images/request_shape02.png"}
              alt=""
              data-aos="fade-left"
              data-aos-delay="400"
            />
          </div>
        </section>

        <section className="team-area pt-60 pb-60">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-xl-12 col-lg-12">
                <div className="section-title mb-40 tg-heading-subheading animation-style3 text-center">
                  <span className="sub-title">ASL Team</span>
                  <h2 className="title tg-element-title">
                    Team Behind the Abhhyam Secured Life
                  </h2>
                </div>
              </div>
            </div>
            <div className="team-item-wrap">
              <div className="row justify-content-center">
                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-8">
                  <div className="team-item">
                    <div className="team-thumb">
                      <img
                        src={"./assets/img/team/team_img01.jpg"}
                        alt="Puran Chand Arya, (Retd) CRPF"
                      />
                    </div>
                    <div className="team-content">
                      <h4 className="title">
                        <Link to="/team-details">
                          Puran Chand Arya, (Retd) CRPF
                        </Link>
                      </h4>
                      <span>Founder Chairman</span>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-8">
                  <div className="team-item">
                    <div className="team-thumb">
                      <img
                        src={"./assets/img/team/team_img02.jpg"}
                        alt="Er. Navin Kumar Sah"
                      />
                    </div>
                    <div className="team-content">
                      <h4 className="title">
                        <Link to="#">Er. Navin Kumar Sah</Link>
                      </h4>
                      <span>Managing Director</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="consulting-area">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="consulting-inner-wrap shine-animate-item">
                  <div className="consulting-content">
                    <div className="content-left">
                      <h2 className="title">40+</h2>
                      <span>
                        Consulting <br /> farm
                      </span>
                    </div>
                    <div className="content-right">
                      <h2 className="title">
                        Trusted, Happy and satisfied Entities
                      </h2>
                      <p>
                        There is a huge need to take various initiative to
                        develop the employment opportunities with secure life of
                        employees.
                      </p>
                    </div>
                  </div>
                  <div className="consulting-img shine-animate">
                    <img
                      src={"./assets/img/images/consulting_img.jpg"}
                      alt=""
                    />
                  </div>
                  <div className="consulting-shape">
                    <img
                      src={"./assets/img/images/consulting_shape.png"}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="testimonial-area">
          <div className="container">
            <div className="row align-items-center justify-content-center">
              <div className="col-lg-6 order-0 order-lg-2">
                <div className="swiper-container testimonial-active">
                  <div className="swiper-wrapper">
                    <div className="swiper-slide">
                      <div className="testimonial-item">
                        <div className="testimonial-info">
                          <h4 className="title">Puran Chand Arya</h4>
                          <span>Founder Chairman, ASL</span>
                        </div>

                        <div className="testimonial-content">
                          <p>
                            "Our ASL HRM Services provide a holistic approach,
                            to set a new standard for service that seamlessly
                            integrates the well-being of both employees and
                            employers, ultimately fostering a harmonious and
                            prosperous workplace."
                          </p>
                          <div className="icon">
                            <i className="fas fa-quote-right"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-8">
                <div className="testimonial-img-wrap">
                  <img src={"./assets/img/images/testimonial_img.png"} alt="" />
                  <div className="img-shape">
                    <img
                      src={"./assets/img/images/testimonial_shape01.png"}
                      alt=""
                    />
                    <img
                      src={"./assets/img/images/testimonial_shape02.png"}
                      alt=""
                      className="alltuchtopdown"
                    />
                    <img
                      src={"./assets/img/images/testimonial_shape03.png"}
                      alt=""
                      data-parallax='{"y" : 80 }'
                    />
                    <img
                      src={"./assets/img/images/testimonial_shape04.png"}
                      alt=""
                      className="rightToLeft"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="testimonial-shape-wrap">
            <img
              src={"./assets/img/images/testimonial_shape05.png"}
              alt=""
              data-aos="fade-up"
              data-aos-delay="400"
            />
            <img
              src={"./assets/img/images/testimonial_shape06.png"}
              alt=""
              data-aos="fade-left"
              data-aos-delay="400"
            />
          </div>
        </section>

        <RequestCall />
      </main>
    </WebLayout>
  );
}

export default Home;
