import React, { useCallback, useEffect, useState } from "react";
import RequestCall from "../components/Requestcall";
import WebLayout from "../layout/WebLayout";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { BaseUrl } from "../Constant/BaseUrl";

function Policy() {
  const { slug } = useParams();

  const [content, setContent] = useState("");
  const [title, setTitle] = useState("");

  const getContentDetails = useCallback(async () => {
    try {
      const response = await axios.get(`${BaseUrl}offer-plan/${slug}`);
      setTitle(response.data.data?.name || "");
      setContent(response.data.data?.content || "");
    } catch (error) {}
  }, [slug]);

  useEffect(() => {
    getContentDetails();
  }, [getContentDetails, slug]);
  return (
    <WebLayout>
      <>
        <main className="fix">
          <section className="breadcrumb__area breadcrumb__bg about_banner_bg">
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <div className="breadcrumb__content">
                    <h2 className="title">{title}</h2>
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                          <Link to="/">Home</Link>
                        </li>
                        <li
                          className="breadcrumb-item active"
                          aria-current="page"
                        >
                          {title}
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="services__details-area">
            <div className="container">
              <div className="services__details-wrap">
                <div className="row">
                  <div className="col-100 order-0 order-lg-2">
                    <div dangerouslySetInnerHTML={{ __html: content }} />
                  </div>
                </div>
              </div>
            </div>
          </section>

          <RequestCall />
        </main>
      </>
    </WebLayout>
  );
}

export default Policy;
