import React, { useEffect } from "react";
import "../scrollbar.css";

const PrivacyModel = ({ privacyModal, setPrivacyModal }) => {
  useEffect(() => {
    if (privacyModal) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }

    return () => document.body.classList.remove("no-scroll");
  }, [privacyModal]);

  return (
    <div
      className={`modal fade show`}
      style={{
        display: privacyModal ? "block" : "none",
        overflow: "hidden",
        background: "rgba(0,0,0,0.2)",
      }}
    >
      <div className="modal-dialog modal-xl scrollbar" id="style-4">
        <div className="modal-content">
          <div
            className="modal-header justify-content-between"
            style={{
              position: "sticky",
              top: 0,
              zIndex: 1050,
              backgroundColor: "#fff",
              display: "flex !important",
              padding: 10,
              flexDirection: "unset",
            }}
          >
            <h4 className={"policyHeading"}>
              Privacy Policy for www.AbhhyamSecure.com
            </h4>
            <button
              type="button"
              className="btn-close"
              onClick={() => setPrivacyModal(false)}
            />
          </div>
          <div>
            <div className="row p-4">
              <div className="col-lg-12">
                <p>
                  At www.AbhhyamSecure.com ("Website"), operated by Abhhyam
                  Secure Private Limited ("we," "our," "us"), we are committed
                  to protecting your privacy and safeguarding your personal
                  information. This Privacy Policy outlines how we collect, use,
                  disclose, and manage your information.
                </p>
                <ul className="listStyleDecimal">
                  <li>
                    <div className="section-title">
                      <h5>Information We Collect</h5>
                    </div>
                    <div className="about__list-box">
                      <p>
                        We may collect the following types of information when
                        you visit our Website or use our services:
                      </p>
                      <ul className="listStyleDisc">
                        <li>
                          <b>Personal Information:</b> Name, contact details
                          (email address, phone number, postal address), date of
                          birth, employee ID, and any other details you provide
                          during registration or service interaction.
                        </li>
                        <li>
                          <b>Account Information:</b> Entity Member Company
                          (EMC), Entity Member Account (EMA), and Entity
                          Representative Member (ERM) details, including ASL ID
                          accounts and associated Pension Points (PP).
                        </li>
                        <li>
                          <b>Payment Information:</b> Bank details, transaction
                          history, and other payment-related information.
                        </li>
                        <li>
                          <b>Usage Information:</b> Browser type, IP address,
                          device type, operating system, and pages accessed on
                          the Website.
                        </li>
                        <li>
                          <b>Cookies and Tracking Data:</b> Information
                          collected through cookies and similar technologies to
                          enhance user experience.
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <div className="section-title mt-1">
                      <h5>How We Use Your Information</h5>
                    </div>
                    <div className="about__list-box mb-2">
                      <p className={"mb-0"}>We use your information to:</p>
                      <ul className="listStyleDisc mb-0">
                        <li>
                          Provide and manage the services under the Abhhyam
                          Secured Life (ASL) plan.
                        </li>
                        <li>
                          Process registrations, payments, and service
                          transactions.
                        </li>
                        <li>
                          Communicate important updates, promotional offers, or
                          changes to policies and services.
                        </li>
                        <li>
                          Improve the Website’s functionality and user
                          experience.
                        </li>
                        <li>
                          Comply with legal obligations and resolve disputes.
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <div className="section-title">
                      <h5>Sharing Your Information</h5>
                    </div>
                    <div className="about__list-box mb-2">
                      <p className={"mb-0"}>
                        Your information may be shared with:
                      </p>
                      <ul className="listStyleDisc mb-0">
                        <li>
                          <b>Service Providers:</b> Third-party entities (e.g.,
                          insurance companies) involved in delivering ASL
                          benefits.
                        </li>
                        <li>
                          <b>Legal Authorities:</b> When required by law or to
                          protect the rights and safety of users and the
                          company.
                        </li>
                        <li>
                          <b>Affiliates and Partners:</b> For operational and
                          marketing purposes, with prior consent where
                          applicable.
                        </li>
                      </ul>
                      <p>
                        We do not sell or rent your personal information to
                        third parties.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="section-title">
                      <h5>Data Security</h5>
                    </div>
                    <div className="about__list-box mb-2">
                      We employ industry-standard security measures to protect
                      your information from unauthorized access, loss, or
                      misuse. However, no method of transmission over the
                      Internet or electronic storage is 100% secure, and we
                      cannot guarantee absolute security.
                    </div>
                  </li>
                  <li>
                    <div className="section-title">
                      <h5>Your Rights</h5>
                    </div>
                    <div className="about__list-box mb-2">
                      <p className={"mb-0"}>You have the right to:</p>
                      <ul className="listStyleDisc mb-0">
                        <li>
                          Access, correct, or update your personal information.
                        </li>
                        <li>
                          Request deletion of your personal data, subject to
                          legal and contractual obligations.
                        </li>
                        <li>
                          Opt out of receiving promotional communications by
                          following the unsubscribe instructions in our emails.
                        </li>
                      </ul>
                      <p>
                        To exercise your rights, contact us at
                        care@abhhyamsecure.com.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="section-title">
                      <h5>Cookies and Tracking Technologies</h5>
                    </div>
                    <div className="about__list-box mb-2">
                      <ul className="listStyleDisc">
                        <li>
                          <b>Cookies:</b> We use cookies to enhance your
                          browsing experience, remember your preferences, and
                          analyze Website traffic.
                        </li>
                        <li>
                          <b>Your Choices:</b> You can manage or disable cookies
                          through your browser settings. Disabling cookies may
                          affect certain functionalities of the Website.
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <div className="section-title">
                      <h5>Third-Party Links</h5>
                    </div>
                    <div className="about__list-box mb-2">
                      Our Website may contain links to third-party websites. We
                      are not responsible for the privacy practices or content
                      of these external sites. We encourage you to review their
                      privacy policies.
                    </div>
                  </li>
                  <li>
                    <div className="section-title">
                      <h5>Children’s Privacy</h5>
                    </div>
                    <div className="about__list-box mb-2">
                      Our services are not intended for individuals under the
                      age of 18. We do not knowingly collect personal
                      information from children. If we become aware of such data
                      collection, we will take steps to delete it.
                    </div>
                  </li>
                  <li>
                    <div className="section-title">
                      <h5>Changes to This Privacy Policy</h5>
                    </div>
                    <div className="about__list-box mb-2">
                      We reserve the right to update this Privacy Policy at any
                      time. Changes will be posted on this page with the updated
                      effective date. We encourage users to review this policy
                      periodically.
                    </div>
                  </li>

                  <li>
                    <div className="section-title">
                      <h5>Contact Us</h5>
                    </div>
                    <div className="about__list-box">
                      <p>
                        If you have any questions or concerns about this Privacy
                        Policy or our data practices, please contact us:
                      </p>
                      <ul className="listStyleDisc">
                        <li>
                          <b>Email:</b> care@abhhyamsecure.com
                        </li>
                        <li>
                          <b>Phone:</b> +91-9971 713 967
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
                <b>Effective Date: [Insert Date]</b>
                <p>
                  Thank you for trusting Abhhyam Secure Private Limited with
                  your information.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PrivacyModel;
