import React, { useEffect, useState } from "react";
import RequestCall from "../components/Requestcall";
import WebLayout from "../layout/WebLayout";
import { Link } from "react-router-dom";
import axios from "axios";
import { BaseUrl } from "../Constant/BaseUrl";
import "../faq.css";

function Policy() {
  const [faqs, setFAQ] = useState([]);
  const [openIndex, setOpenIndex] = useState(null);

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const getFAQ = async () => {
    try {
      const response = await axios.get(`${BaseUrl}faq`);
      setFAQ(response.data.data || []);
    } catch (error) {}
  };

  useEffect(() => {
    getFAQ();
  }, []);

  return (
    <WebLayout>
      <>
        <main className="fix">
          <section className="breadcrumb__area breadcrumb__bg about_banner_bg">
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <div className="breadcrumb__content">
                    <h2 className="title">FAQ</h2>
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                          <Link to="/">Home</Link>
                        </li>
                        <li
                          className="breadcrumb-item active"
                          aria-current="page"
                        >
                          Frequently Asked Questions
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="services__details-area">
            <div className="container">
              <div className="services__details-wrap">
                <div className="row">
                  {faqs.length > 0 ? (
                    <div className="col-100">
                      <h2>Frequently Asked Questions</h2>
                      {faqs?.map((faq, index) => (
                        <div
                          key={index}
                          className={`faq-item ${openIndex === index ? "open" : ""}`}
                          onClick={() => toggleFAQ(index)}
                        >
                          <div className="faq-question">
                            {faq.name}
                            {openIndex === index ? (
                              <i className={"fa fa-chevron-circle-up"} />
                            ) : (
                              <i className={"fa fa-chevron-circle-down"} />
                            )}
                          </div>
                          <div
                            className={"faq-answer"}
                            dangerouslySetInnerHTML={{ __html: faq.content }}
                          />
                        </div>
                      ))}
                    </div>
                  ) : (
                    <p style={{ fontWeight: "bold" }} className={"text-center"}>
                      FAQ Not Found
                    </p>
                  )}
                </div>
              </div>
            </div>
          </section>

          <RequestCall />
        </main>
      </>
    </WebLayout>
  );
}

export default Policy;
